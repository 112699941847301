import PropTypes from "prop-types"
import React from "react"
import {Helmet} from "react-helmet"
import Footer from "../footer"
import { Link } from "gatsby"
import { Container, Row, Col, Navbar, NavbarBrand, button } from 'reactstrap';
import close from "../../images/close.svg" 
// import InputMask from 'react-input-mask'
// import axios from 'axios'
// import 'bootstrap/dist/css/bootstrap.css';
 
import "../global.scss"
import "./style.scss"

class PrivacyPolicy extends React.Component{ 
  constructor (props) {
    super(props)
    this.state = {
        firstName: '',
        lastName: '',
        email:'',
        phoneNumber:'',
        CurrentWebsite:'',
        firstNameError: '',
        lastNameError: '',
        emailError:'',
        phoneNumberError:'',
        CurrentWebsiteError:'',
        validationFunctionCall:false,
        email_error:'',
        website_error:'',
        button:true,
        buttonDisable:false,
        message:'',
        confirmMessage:''
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value,},()=>{
      if (this.state.validationFunctionCall){
        this.validate()
      }
    })
  }
  
  render(){
    return(
      <div>  
        <Helmet>
          <title>Privacy & Policy</title>
          <meta name="description" content="A standout web presence, a simple CRM, easy to use marketing tools, and a powerful listing management platform with IDX and marketplace syndication all built in and all in one place." />
        </Helmet>
        <div className="full__page--header">
          <Container>
            <Row className="align-items-center">
              <Col lx="11" lg="11" sm="11" xs="10">
                <div className="logo-header">
                  Yardpost<span className="divider"></span><span className="page__title">Privacy & Policy</span>
                </div>
              </Col>
              <div lx="1" lg="1" sm="1">
                <div className="close-btn">
                    <Link to={'/'}><img src={close}/></Link>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <section className="terms_page">
              <h3>Effective Date: <span>February 13, 2020</span></h3>
              <p>
                Thanks for visiting Yardpost.io! Yardpost (“Yardpost”, “we”, “us” or “our”) respects your privacy. When it comes to your personal information, we believe in transparency, not surprises. That’s why we’ve set out here what personal information we collect, what we do with it and your choices and rights.
              </p>
              <p>
                By using any of Yardpost’s Services, you confirm you have agreed to the Terms of Service and read and understood this Privacy Policy and our Cookie Policy.
              </p>
              <h1>1. Some key terms</h1>
              <p>
                In our Privacy Policy, when we refer to “Users”, we mean our customers who use our Services, including visitors to our sites. We explain who we are in the “Who is Yardpost?” section below. The users, visitors and customers of, our Users’ sites are “End Users”. Any other capitalized terms not defined in this Privacy Policy have the meanings in our Terms of Service.
              </p>
              <h1>2. How does this Privacy Policy apply?</h1>
              <p>
                This Privacy Policy describes what we do with personal information that we collect and use for our own purposes (i.e., where we are a controller), such as your account information and information about how you use and interact with our Services, including information you submit to our customer support as well as certain information relating to your End Users. This Privacy Policy does not apply to personal information of our employees or job applicants (except to the extent employees or job applicants are Users).
              </p>
              <p>
                We use cookies and similar technologies. Our Cookie Policy describes what we do in that regard.  
              </p>
              <p>
               We also host and process User Content on behalf of our Users. Our Users tell us what to do with User Content, and we follow their instructions. This Privacy Policy does not describe what we do with User Content on our Users’ instructions (i.e., as their processor or service provider). If you are an End User of one of our User’s sites and want to know how a User handles your information, you should check the site's privacy policy. If you want to know about what we do for our own purposes, read on.
              </p>           
              <p>
                Please see our Terms of Service to learn more about how we act as a “Service Provider” if you are a “Business” under the CCPA with respect to data of your “Consumers” (as defined under the CCPA) which meets the definition of “Personal Information” under the CCPA.
              </p>
              <h1>3. Personal information we collect</h1>
              <p>
                We collect various personal information regarding you or your device. This includes the
                following:
              </p>
              <ul className="pl-20">
                <li>
                  •	Information you provide to create an Account, specifically email address, first name and last name. If you sign up for Paid Services, we receive a portion of your payment information from our payment processor (such as the last four digits, the country of issuance and the expiration date of the payment card) and we ask you to select your jurisdiction.
                </li>
                <li>
                  Your marketing preferences.
                </li>
                <li>
                  The emails and other communications that you send us or otherwise contribute, such as customer support inquiries or posts to our customer message boards or forums. Please be aware that information on public parts of our sites is available to others.
                </li>
                <li>
                  Information you share with us in connection with surveys, contests or promotions.
                </li>
                <li>
                  Information from your use of the Services or Users’ sites. This includes: IP addresses, preferences, web pages you visited prior to coming to our or our Users’ sites, information about your browser, network or device (such as browser type and version, operating system, internet service provider, preference settings, unique device IDs and language and other regional settings), information about how you interact with the Services and our Users’ sites (such as timestamps, clicks, scrolling, browsing times, searches, transactions, referral pages, load times, and problems you may encounter, such as loading errors).
                </li>
                <li>
                  Information we get from our partners to support our marketing initiatives, improve our Services and better monitor, manage and measure our ad campaigns, such as details about when a partner of ours shows you one of our ads on or via its advertising platform.
                </li>
                <li>
                  Other information you submit to us directly or through Third Party Services if you use a Third Party Service to create an Account (based on your privacy settings with such Third Party Service).
                </li>
              </ul>
              <h1>4. How we collect personal information</h1>
              <p>We obtain personal information from various sources. We do this in three main ways:</p>
              <ul className="pl-20">
                <li>
                  You provide some of it directly (such as by registering for an Account).
                </li>
                <li>
                  We record some of it automatically when you use our Services or Users’ sites
                  (including with technologies like cookies).
                </li>
                <li>
                  We receive some of it from third parties (like when you register for an account using a
                  Third Party Service or when you make payments to us using our payment processor
                  or via a mobile app store).
                </li>               
              </ul>
              <p>We’ve described this in more detail below.</p>
              <ol type="a" className="ollist">
                <li>Personal information you provide</li>
              
              <p>
                When you use our Services, we collect information from you in a number of ways.
                For instance, we ask you to provide your name and email address to register and
                manage your Account. We also maintain your marketing preferences and the
                emails and other communications that you send us or otherwise contribute, such as customer support inquiries or posts to our customer message boards or forums.
                You might also provide us with information in other ways, including by responding
                to surveys, submitting a form or participating in contests or similar promotions.
              </p>
              <p>
                Sometimes we require you to provide us with information for contractual or legal
                reasons. For example, we may ask you to select your jurisdiction when you sign up
                for Paid Services to determine if, and how much, tax we need to collect from you.
                We’ll normally let you know when information is required, and the consequences of
                failing to provide it. If you do not provide personal information when requested, you
                may not be able to use our Services if that information is necessary to provide you
                with the service or if we are legally required to collect it.
              </p>
              
              <li>
                Personal information obtained from your use of our Services
              </li>
             
              <p>
                When you use our Services, we collect information about your activity on and
                interaction with the Services, such as your IP address(es), your device and browser
                type, the web page you visited before coming to our sites, what pages on our sites
                you visit and for how long and identifiers associated with your devices. If you’ve
                given us permission through your device settings, we may collect your location
                information in our mobile apps.
              </p>
              <p>
                If you are an End User of our Users’ sites, we also get information about your
                interactions with their sites, though we use this in anonymous, aggregated or
                pseudonymized form which does not focus on you individually. We use this data to
                evaluate, provide, protect or improve our Services (including by developing new
                products and services).
              </p>
              <p>
                Some of this information is collected automatically using cookies and similar
                technologies when you use our Services and our Users’ sites. We let our Users
                control what cookies and similar technologies are used through their sites (except
                those we need to use to properly provide the Services, such as for performance or
                security related reasons). You can read more about our use of cookies in our Cookie Policy. Some of this information is similarly collected automatically
                through your browser or from your device.
              </p>
               
                <li>Personal information obtained from other sources</li>
              </ol>
              <p className="text__pl-20">
                If you use a Third Party Service (such as Google) to register for an Account, the
                Third Party Service may provide us with your Third Party Service account
                information on your behalf, such as your name and email address (we don’t collect
                or store passwords you use to access Third Party Services). Your privacy settings
                on the Third Party Service normally control what they share with us. Make sure you
                are comfortable with what they share by reviewing their privacy policies and, if
                necessary, modifying your privacy settings directly on the Third Party Service.
              </p>
              <p className="text__pl-20">
                If you sign up for Paid Services directly with us, we obtain limited information about
                your payment card from our payment processor, such as the last four digits, the
                country of issuance and the expiration date. Currently, our payment processor is
                Stripe. Stripe uses and processes your complete payment information in
                accordance with Stripe’s privacy policy. This paragraph is not applicable if you sign
                up for Paid Services as an in-app purchase via a mobile app store. Please see
                Google&#39;s privacy policy and Apple&#39;s privacy policy for information about how they
                use and process your payment information.
              </p>
              <h1>5. How we use your personal information</h1>
              <p>
                We use the personal information we obtain about you for the following purposes:
              </p>
              <ul className="pl-20">
                <li>
                  Provision of the Services. Create and manage your Account, provide and personalize
                  our Services, process payments and respond to your inquiries.
                </li>
                <li>
                  Communicating with you. Communicate with you, including by sending you emails
                  about your transactions and Service-related announcements.
                </li>
                <li>
                  Surveys and contests. Administer surveys, contests and other promotions.
                </li>
                <li>
                  Promotion. Promote our Services and send you tailored marketing communications
                  about products, services, offers, programs and promotions of Yardpost and our
                  partners and measure the success of those campaigns. For example, we may send different marketing communications to you based on your subscription plan or what
                  we think may interest you based on other information we hold about you.
                </li>
                <li>
                  Advertising. Analyze your interactions with our Services and third parties’ online
                  services so we can tailor our advertising to what we think will interest you. For
                  example, we may decide not to advertise our Services to you on a social media site if
                  you already signed up for Paid Services or we may choose to serve you a particular
                  advertisement based on your subscription plan or what we think may interest you
                  based on other information we hold about you.
                </li>
                <li>
                  Customizing the Services. Provide you with customized services. For example, we use
                  your location information to determine your language preferences or display accurate
                  date and time information. We also use cookies and similar technologies for this
                  purpose, such as remembering which of Your Sites you most recently edited.
                </li>
                <li>
                  Improving our Services. Analyze and learn about how the Services are accessed and
                  used, evaluate and improve our Services (including by developing new products and
                  services and managing our communications) and monitor and measure the
                  effectiveness of our advertising. We usually do this based on anonymous,
                  pseudonymized or aggregated information which does not focus on you individually.
                  For example, if we learn that most Users of Paid Services use a particular integration
                  or feature, we might wish to expand on that integration or feature.                  
                </li>
                <li>
                  Security. Ensure the security and integrity of our Services.
                </li>
                <li>
                  Third party relationships. Manage our vendor and partner relationships.
                </li>
                <li>
                  Enforcement. Enforce our Terms of Service and other legal terms and policies.
                </li>
                <li>
                  Protection. Protect our and others’ interests, rights and property (e.g., to protect our
                  Users from abuse).
                </li>
                <li>
                  Complying with law. Comply with applicable legal requirements, such as tax and other
                  government regulations and industry standards, contracts and law enforcement
                  requests.
                </li>
              </ul>
              <p>We process your personal information for the above purposes when:</p>
              <ul className="pl-20">
                <li>
                  Consent. You have consented to the use of your personal information in a particular
                  way. When you consent, you can change your mind at any time.
                </li>
                <li>
                  Performance of a contract. We need your personal information to provide you with
                  services and products requested by you, or to respond to your inquiries. In other
                  words, so we can perform our contract with you or take steps at your request before
                  entering into one. For example, we need your email address so you can sign in to your
                  Yardpost account.
                </li>
                <li>
                  Legal obligation. We have a legal obligation to use your personal information, such as
                  to comply with applicable tax and other government regulations or to comply with a
                  court order or binding law enforcement request.
                </li>
                <li>
                  Legitimate interests. We have a legitimate interest in using your personal information.
                  In particular, we have a legitimate interest in the following cases:
                </li>                
              </ul>
              <ul>
                <li>
                  To operate the Yardpost business and provide you with tailored advertising and
                  communications to develop and promote our business.
                </li>
                <li>
                  To analyze and improve the safety and security of our Services - we do this as it is
                  necessary to pursue our legitimate interests in ensuring Yardpost is secure, such
                  as by implementing and enhancing security measures and protections and
                  protecting against fraud, spam and abuse.
                </li>
                <li>
                  To provide and improve the Services, including any personalized services - we do
                  this as it is necessary to pursue our legitimate interests of providing an innovative
                  and tailored offering to our Users on a sustained basis.
                </li>
                <li>
                  To share your personal information with other Yardpost group companies that help
                  us provide and improve the Services.
                </li>
                <li>
                  To anonymize and subsequently use anonymized information.
                </li>
              </ul>
              <ul className="pl-20">
                <li>
                  Protecting you and others. To protect your vital interests, or those of others.
                </li>
                <li>
                  Others’ legitimate interests. Where necessary for the purposes of a third party’s
                  legitimate interests, such as our partners who have a legitimate interest in delivering
                  tailored advertising to you and monitoring and measuring its effectiveness or our
                  Users who have a legitimate interest in having their sites function properly and securely and analyzing the usage of their sites so they can understand trends and
                  improve their services.
                </li>                 
              </ul>
              <h1>6. How we share your personal information</h1>
              <p>We share personal information in the following ways:</p>
              <ul className="pl-20">
                <li>
                  Affiliates. We share personal information with our affiliates when it is reasonably
                  necessary or desirable, such as to help provide services to you or analyze and
                  improve the services we or they provide.
                </li>
                <li>
                  Users.  We share with our Users data regarding usage by End Users of their sites. For
                  example, we provide a User with information about what web page the End User
                  visited before coming to their site and how their End Users interacted with their site.
                  This is so Users can analyze the usage of their sites and improve their services.
                </li>
                <li>
                  Business partners.  We may share personal information with business partners. For
                  example, we may share your personal information when our Services are integrated
                  with their Third Party Services, but only when you have been informed or would
                  otherwise expect such sharing.
                </li>
                <li>
                  Service providers. We share personal information with our service providers that
                  perform services on our behalf. For example, we may use third parties to help us
                  provide customer support, manage our advertisements on other sites, send marketing
                  and other communications on our behalf or assist with data storage.
                </li>
                <li>
                  Process payments. We transmit your personal information via an encrypted
                  connection to our payment processor.
                </li>
                <li>
                  Following the law or protecting rights and interests. We disclose your personal
                  information if we determine that such disclosure is reasonably necessary to comply
                  with the law, protect our or others’ rights, property or interests (such as enforcing
                  our Terms of Service) or prevent fraud or abuse of Yardpost or our Users or End
                  Users. In particular, we may disclose your personal information in response to lawful
                  requests by public authorities, such as to meet national security or law enforcement
                  requirements.
                </li>
                <li>
                  Advertising. We share personal information with third parties so they and we can
                  provide you with tailored advertising and measure and monitor its effectiveness. For example, we may share your pseudonymized email address with a third party social
                  media platform on which we advertise to avoid serving Yardpost ads to people who
                  already use Yardpost.
                </li>
                <li>
                  Business transfers. If we&#39;re involved in a reorganization, merger, acquisition or sale of
                  some or all of our assets, your personal information may be transferred as part of that
                  deal or the negotiation of contemplated deals.
                </li>
              </ul>
                <h1>7. Your rights and choices</h1>
                <p>
                  Where applicable law requires (and subject to any relevant exceptions under law), you
                  may have the right to access, update, change or delete personal information.
                </p>
                <p>
                  You can access, update, change or delete personal information (or that of your End
                  Users) either directly in your Account or by contacting us at <a href="mailto:privacy@yardpost.io">privacy@yardpost.io</a> to
                  request the required changes. You can exercise your other rights (including deleting your
                  Account) by contacting us via your Account at the same email address. Please note that
                  we may need to verify your identity in connection with your requests, and such
                  verification process may, if you do not have access to your Account, require you to
                  provide us with additional information (e.g. government identification). Even if you have
                  access to your Account, we may request additional information if we believe it’s
                  necessary to verify your identity. If we are unable to verify your identity or request, we
                  may not, in accordance with applicable law, be able to fulfill your request.
                </p>
                <p>
                  You can also elect not to receive marketing communications by changing your
                  preferences in your Account or by following the unsubscribe instructions in such
                  communications.
                </p>
                <p>
                  Please note that, for technical reasons, there is likely to be a delay in deleting your
                  personal information from our systems when you ask us to delete it. We also will retain
                  personal information in order to comply with the law, protect our and others’ rights,
                  resolve disputes or enforce our legal terms or policies, to the extent permitted under
                  applicable law.
                </p>
                <p>
                  You may have the right to restrict or object to the processing of your personal information
                  or to exercise a right to data portability under applicable law. You also may have the right
                  to lodge a complaint with a competent supervisory authority, subject to applicable law.
                </p>
                <p>
                  Additionally, if we rely on consent for the processing of your personal information, you
                  have the right to withdraw it at any time and free of charge. When you do so, this will not
                  affect the lawfulness of the processing before your consent withdrawal.
                </p>
                <p>
                  Our Cookie Policy explains how you can manage cookies and similar technologies.
                </p>
                <p>
                  If you are an End User of one of our User’s sites, you should contact them to exercise
                  your rights with respect to any information they hold about you.
                </p>
                <h1>8. How we protect your personal information</h1>
                <p>
                  While no service is completely secure, we have a security team dedicated to keeping
                  personal information safe. We maintain administrative, technical and physical safeguards
                  that are intended to appropriately protect against accidental or unlawful destruction,
                  accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse and
                  any other unlawful form of processing, of the personal information in our possession. We
                  employ security measures such as using firewalls to protect against intruders, building
                  redundancies throughout our network (so that if one server goes down, another can
                  cover for it) and testing for and protecting against network vulnerabilities.
                </p>
                <h1>9. How we retain your personal information</h1>
                <p>
                  We retain personal information regarding you or your use of the Services for as long as
                  your Account is active or for as long as needed to provide you or our Users with the
                  Services. We also retain personal information for as long as necessary to achieve the
                  purposes described in this Privacy Policy, for example, to comply with our legal
                  obligations, to protect us in the event of disputes and to enforce our agreements and to
                  protect our and others’ interests.
                </p>
                <p>
                  The precise periods for which we keep your personal information vary depending on the
                  nature of the information and why we need it. Factors we consider in determining these
                  periods include the minimum required retention period prescribed by law or
                  recommended as best practice, the period during which a claim can be made with
                  respect to an agreement or other matter, whether the personal information has been
                  aggregated or pseudonymized, and other relevant criteria. For example, the period we
                  keep your email address is connected to how long your Account is active, while the
                  period for which we keep a support message is based on how long has passed since the
                  last submission in the thread.
                </p>
                <p>
                  As Users may have a seasonal site or come back to us after an Account becomes
                  inactive, we don’t immediately delete your personal information when your trial expires or
                  you cancel all Paid Services. Instead, we keep your personal information for a
                  reasonable period of time, so it will be there for you if you come back.
                </p>
                <p>
                  You may delete your Account by contacting us at <a href="mailto:privacy@yardpost.io">privacy@yardpost.io</a> and Yardpost will
                  delete the personal information it holds about you (unless we need to retain it for the
                  purposes set out in this Privacy Policy).
                </p>
                <p>
                  Please note that in the course of providing the Services, we collect and maintain
                  aggregated, anonymized or de-personalized information which we may retain indefinitely.
                </p>
                <h1>10. Data transfers</h1>
                <p>
                  Personal information that you submit through the Services may be transferred to
                  countries other than where you live, such as, for example, to our servers in the U.S. We
                  also store personal information locally on the devices you use to access the Services.
                </p>
                <p>
                  Your personal information may be transferred to countries that do not have the same
                  data protection laws as the country in which you initially provided the information.
                </p>
                <p>
                  We rely upon a number of means to transfer personal information which is subject to the
                  European General Data Protection Regulation (“GDPR”) in accordance with Chapter V of
                  the GDPR.  These include:
                </p>
                <ul className="pl-20">
                  <li>
                    Privacy Shield. We transfer, in accordance with Article 45 of the GDPR, personal
                    information to companies that have certified their compliance with the EU-U.S. or
                    Swiss-U.S. Privacy Shield Frameworks (each individually and jointly, the “Privacy
                    Shield”), including Yardpost, LLC.
                  </li>
                  <li>
                    Standard data protection clauses. We may, in accordance with Article 46 of the
                    GDPR, transfer personal information to recipients that have entered into the European
                    Commission approved contract for the transfer of personal data outside the European
                    Economic Area.
                  </li>
                  <li>
                    Other means. We may, in accordance with Articles 45 and 46 of the GDPR, transfer
                    personal information to recipients that are in a country the European Commission or a
                    European data protection supervisory authority has confirmed, by decision, offers an
                    adequate level of data protection, pursuant to an approved certification mechanism or
                    code of conduct, together with binding enforcement commitments from the recipient to
                    apply the appropriate safeguards, including as regards data subjects’ rights, or to
                    processors which have committed to comply with binding corporate rules.
                  </li>
                </ul>
                <p> 
                  You can find out more information about these transfer mechanisms here.
                </p>
                <h1>11. End Users’ personal information</h1>
                <p>
                  Our customers who have created a site using Yardpost are responsible for what they do
                  with the personal information they collect, directly or through Yardpost, about their End
                  Users.  This Section 12 is directed to such customers.
                </p>
                <ol type="a" className="ollist_two">
                  <li>Your relationship with End Users</li>
               
                <p>
                  If you’re one of our Users, you will collect personal information about your End
                  Users. For example, during checkout you may ask your End Users to provide their
                  name, address, email address and payment information so that you can complete their orders. You may also use cookies and similar technologies to analyze usage
                  and other trends.
                </p>
                <p>
                  You&#39;re solely responsible for complying with any laws and regulations that apply to
                  your collection and use of your End Users’ information, including personal
                  information you collect about them from us or using Yardpost functionality or
                  cookies or similar technologies.
                </p>
                <p>
                  You must publish your own privacy and cookie policies and comply with them.
                </p>
                <p>
                  We’re not liable for your relationship with your End Users or how you collect and
                  use personal information about them (even if you collect it from us or using
                  Yardpost functionality or cookies or similar technologies) and we won’t provide you
                  with any legal advice regarding such matters.
                </p>
            
                  <li>End User payment information</li>
                </ol>
                <p>
                  Your End Users’ payment information may be processed via third party
                  eCommerce Payment Processors with which you integrate your Account, in
                  accordance with such eCommerce Payment Processors’ terms and policies. We
                  transmit your End Users’ complete payment information when they initially provide
                  or update it only so that we can pass it along to the eCommerce Payment
                  Processors you agree to use. We don’t collect or store your End Users’ payment
                  information.
                </p>
                <h1>12. California Residents</h1>
                <p>
                  This Section 13 is only applicable to you if you are a resident of the state of California in
                  the US (“California Residents”) and only applies to personal information for which
                  Yardpost is a “Business” (as defined in the CCPA), but does not apply to personal
                  information we collect from you in the course of our provision of services to you where
                  you are an employee, owner, director, officer or contractor of a company, partnership,
                  sole proprietorship, non-profit or government agency. It applies to personal information
                  we collect from California Residents on or through our Services and through other means (such as information collected offline or in person). With respect to personal information
                  for which you are a “Business” and Yardpost is a “Service Provider,” please see Section
                  3.7 of our Terms of Service.
                </p>
                <ol type="a" className="ollist">
                  <li>Categories, business and commercial purposes, sources and third parties</li>
                
                <p>
                  California law requires us to disclose certain information regarding the categories of
                  personal information we collect. The following is a list of all categories of personal
                  information which Yardpost collects, or has collected, from California Residents, in
                  the twelve (12) months prior to the effective date of this Privacy Policy (each, a
                  “Category”), together with, for each Category: (1) the categories of sources from
                  which such Category is collected; and (2) the categories of third parties with whom
                  we share such Category.
                </p>
                <ul className="pd-0">
                  <li>
                    Identifiers: (1) sources: you (either directly or through a Third Party Service),
                    collected by the Services, business partners, service providers and payment
                    processors; and (2) third parties: business partners, service providers and
                    payment processors.
                  </li>
                  <li>
                    Commercial information: (1) sources: you (either directly or through a Third Party
                    Service), collected by the Services, business partners and service providers;
                    and (2) third parties: business partners, service providers and payment
                    processors.
                  </li>
                  <li>
                    Financial data: (1) sources: you (either directly or through a Third Party Service),
                    business partners and payment processors; and (2) third parties: business
                    partners, service providers and payment processors.
                  </li>
                  <li>
                    Internet or other network or device activity: (1) sources: you (either directly or
                    through a Third Party Service), collected by the Services, business partners,
                    service providers and payment processors; and (2) third parties: business
                    partners, service providers and payment processors.
                  </li>
                  <li>
                    Location information: (1) sources: you (either directly or through a Third Party
                    Service), collected by the Services, business partners and service providers;
                    and (2) third parties: business partners, service providers and payment
                    processors.
                  </li>
                  <li>
                    Sensory information: (1) sources: you (either directly or through a Third Party
                    Service) and service providers; and (2) third parties: business partners, service
                    providers and payment processors.
                  </li>
                  <li>
                    Other information that identifies or can be reasonably associated with you: (1)
                    sources: you (either directly or through a Third Party Service), collected by the
                    Services, business partners, service providers and payment processors; and (2)
                    third parties: business partners, service providers and payment processors.
                  </li>
                </ul>
                <p>
                  Each Category is collected for the following business purposes: (i) providing the
                  Services (including without limitation maintaining your Account, processing and
                  fulfilling orders, and administering surveys and contests); (ii) providing customer
                  support for the Services; (iii) operating the Services (including without limitation
                  managing third party relationships and enabling usage of our service providers); (iv)
                  communicating with you; (v) customizing the Services; (vi) securing and protecting
                  the Services (including without limitation auditing the Services, bug and fraud
                  detection, debugging and repair of errors and the detection, protection and
                  prosecution of security incidents or illegal activity); (vii) enforcing our terms and
                  policies; (viii) complying with law; (ix) verifying your identity; and (x) other business
                  purposes about which we may notify you from time to time.
                </p>
                <p>Each Category is collected for uses that advance our commercial or economic
                  interests such as: (i) promoting surveys and contests; (ii) promoting and advertising
                  the Services; (iii) customizing the Services; (iv) improving our Services; (v)
                  communicating with you about relevant offers from third parties; and (vi) other
                  commercial purposes about which we may notify you from time to time.
                </p>
                
                  <li>Your requests</li>
         

                <p>
                  Subject to certain exceptions and restrictions, the CCPA provides California
                  Residents the right to submit requests to a business which has collected their
                  personal information: (i) to provide them with access to the specific pieces and
                  categories of personal information collected by the business about such California
                  Resident, the categories of sources for such information, the business or
                  commercial purposes for collecting such information, and the categories of third
                  parties with which such information was shared; and (ii) to delete such personal
                  information (each, a “California Request”). We need certain types of information so
                  that we can provide our Services to you. If you ask us to delete some or all of your
                  information, you may no longer be able to access or use the Services.
                </p>
                <p>
                  If you are a California Resident, please follow the instructions in the “Your rights
                  and choices” section above to submit California Requests and please make sure
                  you note that you are a California Resident when you do so. California Residents
                  may designate an authorized agent to make California Requests on their behalf. In
                  order to designate an authorized agent to make a California Request on your
                  behalf, you must provide a valid power of attorney, the requester’s valid
                  government issued identification and the authorized agent’s valid government
                  issued identification.
                </p>
             
                  <li>We do not sell your personal information</li>
                <p >
                  We do not sell your personal information, as that term is defined based on our
                  understanding of the CCPA and its implementing regulations. We have not sold any
                  personal information to third parties for a business or commercial purpose in the 12
                  months prior to the effective date of this Privacy Policy. We do share personal
                  information with third parties for the business and commercial purposes described
                  in this Section 13, including without limitation advertising and marketing based on
                  Users’ online activities over time and across different sites, services and devices.
                </p>
               
                  <li>We do not discriminate against you</li>
                </ol>
                <p className="text__pl-20">
                  You also have the right to not be discriminated against (as provided for in
                  applicable law) for exercising certain of your rights. Yardpost does not discriminate
                  against California Residents for exercising their rights.
                </p>
                <h1>13. Updates to this Privacy Policy</h1>
                <p>
                  We’ll update this Privacy Policy from time to time to reflect changes in technology, law, our business operations or any other reason we determine is necessary or appropriate. When we make changes, we’ll update the “Effective Date” at the top of the Privacy Policy and post it on our sites. If we make material changes to it or the ways we process personal information, we’ll notify you (by, for example, prominently posting a notice of the changes on our sites before they take effect or directly sending you a notification).
                </p>
                <p>
                  We encourage you to check back periodically to review this Privacy Policy for any changes since your last visit.  This will help ensure you better understand your relationship with us, including the ways we process your personal information.
                </p>
                <h1>14. Who is Yardpost?</h1>
                <p>
                  When we say “Yardpost” (or “we”, “us” or “our”), we mean: (a) Yardpost, LLC.
                </p>
                <h1>15. Disclaimer</h1>
                <p>
                The information provided by “Yardpost” (“we,” “us” or “our”) on  (the <a href="https://yardpost.io/">www.yardpost.io</a>) is for general informational purposes only. All information on the Site is provided in good faith, however we make no
                representation or warranty of any kind, express or implied, regarding the
                accuracy, adequacy, validity, reliability, availability or completeness of any
                information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
                </p>
                <h1>16. How to contact us</h1>
                <p>
                  If you have questions, comments or complaints about this Privacy Policy or our privacy
                  practices or if you would like to exercise your rights and choices, please email us
                  at <a href="mailto:privacy@yardpost.io">privacy@yardpost.io</a> or write to us at the addresses below:
                </p>
                <p>
                  Yardpost, LLC<br/>
                  28-07 Jackson Avenue, 9th Floor<br/>
                  Long Island City, NY 11101 United States<br/>
                </p>
                <h1>17. Disclosure</h1>
                <p>
                yardpost.io app use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
                </p>
                
             </section>
          </Row>
        </Container>
        <Footer/>
      </div>
    )
  }
}

PrivacyPolicy.propTypes = {
  siteTitle: PropTypes.string,
}

PrivacyPolicy.defaultProps = {
  siteTitle: ``,
}

export default PrivacyPolicy