import React from "react"
import Layout from "../components/layout"
import PrivacyPolicypage from "../components/PrivacyPolicy"

const PrivacyPolicy = () => (
  <Layout>
    <PrivacyPolicypage/>
  </Layout>
)

export default PrivacyPolicy
